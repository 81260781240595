
        <template>
          <div class="vc-snippet-doc">
            <h2>Qrcode 二维码</h2>
<p>二维码：支持多个二维码同时渲染。</p>
<h3>基础用法</h3>
<p>基础的用法。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"100"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>进阶用法</h3>
<p>进阶用法。</p>
<vc-snippet>
                  <div slot="desc"><p>改变<code>color</code> 和 <code>size</code></p>
</div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">el-row</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"100"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"blue"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"125"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"#e74c3c"</span> <span class="hljs-attr">bg-color</span>=<span class="hljs-string">"gold"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"150"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"#34495e"</span> <span class="hljs-attr">bg-color</span>=<span class="hljs-string">"#2ecc71"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">el-row</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>高级用法</h3>
<p>高级用法。</p>
<vc-snippet>
                  <div slot="desc"><p>改变<code>error-level</code></p>
</div>
                  <vc-snippent-2 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">el-row</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"100"</span> <span class="hljs-attr">error-level</span>=<span class="hljs-string">"L"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"100"</span> <span class="hljs-attr">error-level</span>=<span class="hljs-string">"M"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"100"</span> <span class="hljs-attr">error-level</span>=<span class="hljs-string">"Q"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-col</span> <span class="hljs-attr">:span</span>=<span class="hljs-string">"6"</span> <span class="hljs-attr">:gutter</span>=<span class="hljs-string">"12"</span>&gt;</span>
            <span class="hljs-tag">&lt;<span class="hljs-name">ixu-qrcode</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Your text"</span> <span class="hljs-attr">:size</span>=<span class="hljs-string">"100"</span> <span class="hljs-attr">error-level</span>=<span class="hljs-string">"H"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-qrcode</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-col</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">el-row</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>text</td>
<td>内容</td>
<td>String</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>size</td>
<td>尺寸</td>
<td>Number</td>
<td>—</td>
<td>256</td>
</tr>
<tr>
<td>color</td>
<td>颜色</td>
<td>String</td>
<td>—</td>
<td>'#000'</td>
</tr>
<tr>
<td>bg-color</td>
<td>背景色</td>
<td>String</td>
<td>—</td>
<td>'#FFF'</td>
</tr>
<tr>
<td>error-level</td>
<td>误差校正</td>
<td>String</td>
<td>'L', 'M', 'Q', 'H'</td>
<td>'H'</td>
</tr>
</tbody>
</table>
<h3>PS:</h3>
<p>参考自：<a href="https://github.com/gerardreches">vue-qrcode-component</a></p>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ixu-qrcode',{attrs:{"text":"Your text","size":100}})],1)}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":100,"color":"blue"}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":125,"color":"#e74c3c","bg-color":"gold"}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":150,"color":"#34495e","bg-color":"#2ecc71"}})],1)],1)}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-2":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":100,"error-level":"L"}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":100,"error-level":"M"}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":100,"error-level":"Q"}})],1),_vm._v(" "),_c('el-col',{attrs:{"span":6,"gutter":12}},[_c('ixu-qrcode',{attrs:{"text":"Your text","size":100,"error-level":"H"}})],1)],1)}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>